<template>
  <div class="separator text-uppercase text-center text-light">
    <span>&nbsp;</span>
  </div>
</template>

<script>
export default {
  name: "HeaderSeparator",
  components: {},
};
</script>

<style lang="scss" scoped>
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
.separator {
  position: relative;
  margin-top: 7px;
  font-size: 23px;
  letter-spacing: 0.22em;

  @include media("screen", ">=tablet") {
    margin-top: 11px;
  }

  &:before {
    content: "";
    position: absolute;
    left: -9999px;
    right: -9999px;
    top: 0;
    bottom: 0;
    background-image: url("@/esf_lommel_dt1_europe/assets/DT1Pics/Lines.svg");
    background-size: auto 100%;
  }

  span {
    display: inline-block;
    position: relative;
    padding-left: 2px;
    padding-right: 2px;
    z-index: 0;
    line-height: 1.5;
    color: black;

    &:before {
      content: "";
      position: absolute;
      left: -11px;
      right: -12px;
      top: -2px;
      bottom: -2px;
      z-index: -2;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: -1px;
      bottom: 0;
      z-index: -1;
      @include media("screen", ">=desktop") {
        left: -1px;
        right: -2px;
      }
    }
  }
}
</style>
