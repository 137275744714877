<template>
  <header id="header" class="pt-6 pt-md-10 pb-md- pb-lg-1">
    <b-container>
      <div class="d-flex align-items-center position-relative">
        <div class="logo mr-lg-20">
          <b-link :to="$link('/')"
            ><b-img
              :src="require('@/esf_lommel_dt1_europe/assets/DT1Pics/DT1Logo-new.png')"
              :srcset="
                require('!!url-loader?limit=0!@/esf_lommel_dt1_europe/assets/DT1Pics/DT1Logo-new.png') +
                ' 253w ,' +
                require('!!url-loader?limit=0!@/esf_lommel_dt1_europe/assets/DT1Pics/DT1Logo-new.png') +
                ' 175w, ' +
                require('!!url-loader?limit=0!@/esf_lommel_dt1_europe/assets/DT1Pics/DT1Logo-new.png') +
                ' 75w '
              "
              width="190"
              height="97"
              alt="Logo"
            ></b-img>
          </b-link>
        </div>
        <div
          class="d-none d-lg-flex align-items-center justify-content-end search-and-icons"
        >
          <HeaderSearch />
          <HeaderTopIcons showType="O" />
        </div>
      </div>
      <HeaderSeparator />
      <div class="d-flex pt-1 pt-lg-0 position-relative navigatie">
        <Navbar />
        <div
          class="d-flex d-lg-none align-items-center justify-content-end search-and-icons pt-6"
        >
          <HeaderSearch />
          <HeaderTopIcons showType="M" />
        </div>
      </div>
    </b-container>
  </header>
</template>

<script>
import HeaderSearch from "@/esf_lommel_dt1_europe/core/components//HeaderSearch";
import HeaderTopIcons from "@/esf_lommel_dt1_europe/core/components//HeaderTopIcons";
import HeaderSeparator from "@/esf_lommel_dt1_europe/core/components//HeaderSeparator";
import Navbar from "@/esf_lommel_dt1_europe/core/components//Navbar";

export default {
  name: "Header",
  components: {
    HeaderSearch,
    HeaderTopIcons,
    HeaderSeparator,
    Navbar,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";

#header {
  position: relative;
  z-index: 999;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);

  @include media("screen", ">=desktop") {
    // border-bottom: 1px solid #000;
    box-shadow: none;
  }
}
.logo {
  width: 231px;
  margin-right: 10px;

  a {
    display: block;
  }
}

.search-and-icons {
  flex-grow: 1;
  // margin-left: 100px;

  @include media("screen", ">=desktop") {
    position: static;
    padding-top: 4px;
  }
}

.navigatie {
  justify-content: center;
}
</style>
