<template>
  <div id="wrapper">
    <Header />
    <main id="main">
      <slot></slot>
    </main>
    <Footer />
    <!-- <CookieBarWrap /> -->
  </div>
</template>

<script>
import Header from "@/esf_lommel_dt1_europe/core/components//Header";
import Footer from "@/esf_lommel_dt1_europe/core/components//Footer";
//import CookieBarWrap from "@/esf_lommel_dt1_europe/core/components//CookieBar";
export default {
  name: "BaseLayout",
  components: {
    Header,
    Footer,
    //CookieBarWrap,
  },
};
</script>

<style lang="scss" scoped></style>
