<template>
  <nav id="nav">
    <b-link href="#" class="nav-opener" @click="toggleNav"
      ><span></span
    ></b-link>
    <div
      class="nav-overlay"
      @click="toggleNav"
      :class="{ show: navExpanded }"
    ></div>
    <div class="fake-nav-overlay" :class="{ show: navExpanded }"></div>
    <transition name="slide-fade">
      <div class="nav-collapse" :class="{ show: navExpanded }">
        <NavbarContent @clicked="toggleNav" />
      </div>
    </transition>
  </nav>
</template>

<script>
import NavbarContent from "./NavbarContent.vue";

export default {
  name: "Navbar",
  components: {
    NavbarContent,
  },
  data: () => ({}),
  computed: {
    navExpanded() {
      return this.$store.getters["menu/getNavbar"];
    },
  },

  mounted() {
    window.addEventListener("resize", this.resize);
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    onClick() {},
    toggleNav() {
      this.$store.dispatch("menu/toggleNavbar");
    },
    resize() {
      document.body.classList.add("resize-active");
      if (window.innerWidth > 991) this.navExpanded = false;
      clearTimeout(this.resizeTimer);
      this.resizeTimer();
    },
    resizeTimer() {
      setTimeout(() => {
        document.body.classList.remove("resize-active");
      }, 400);
    },
  },
};
</script>

<style lang="scss" scoped>
//@import "@/esf_lommel_dt1_europe/scss/main.scss";
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
@import "@/esf_lommel_dt1_europe/scss/custom-bootstrap.scss";
@import "@/esf_lommel_dt1_europe/scss/mixins.scss";
#nav {
  padding: 15px 0;

  @include media("screen", ">=desktop") {
    padding: 0;
    // width: 100%;
    // position: absolute;
    // background-color: red;
  }
}

.resize-active * {
  transition: none !important;
}

.nav-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  visibility: hidden;
  z-index: 222;
  @include animate(opacity visibility);
  transition-delay: 0.3s;

  &.show {
    transition-delay: 0;
    visibility: visible;
    opacity: 0.9;
  }
}

.fake-nav-overlay {
  @include media("screen", "<desktop") {
    transition: all 0.3s ease 0.3s;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background: white;
    z-index: 99999;
    width: 90%;
    max-width: 370px;
    transform: translateX(-100%);
  }

  &.show {
    @include media("screen", "<desktop") {
      transition: all 0.5s ease;
      transform: translateX(0);
    }
  }
}

.nav-collapse {
  @include animate(opacity visibility);
  transition-delay: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 90%;
  max-width: 375px;
  overflow-y: auto;
  background: white;
  padding: 19px 19px 19px 14px;
  z-index: 99999;
  visibility: hidden;
  opacity: 0;

  @include media("screen", ">=desktop") {
    position: static;
    width: auto;
    max-width: none;
    overflow: visible;
    padding: 0;
    background: none;
    visibility: visible;
    opacity: 1;
  }

  &.show {
    transition-delay: 0.4s;
    visibility: visible;
    opacity: 1;
  }
}

.nav-opener {
  display: block;
  border: none;
  outline: none !important;
  padding: 0;

  width: 32px;
  height: 22px;
  position: relative;
  z-index: 0;

  @include media("screen", ">=desktop") {
    display: none;
  }

  &:before,
  &:after,
  span {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    height: 2px;
    background: #000;
    @include animate(transform opacity top);
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }

  span {
    top: 50%;
    transform: translateY(-50%);
  }

  &.inner-close {
    position: absolute;
    top: 22px;
    right: 15px;
    width: 24px;

    &:before,
    &:after,
    span {
      height: 0.14rem;
    }

    &:before {
      transform: rotate(45deg);
      top: 9px;
    }
    &:after {
      transform: rotate(-45deg);
      top: 9px;
    }

    span {
      opacity: 0;
    }
  }
}
</style>
